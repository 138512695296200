export const BASE__API__URL = process.env.REACT_APP_API || 'http://localhost:3000/api/';
export const BASE__MEDIA__URL = process.env.REACT_APP_MEDIA_SRC || 'http://localhost:3000/api/';
export const BASE__APP__URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3000/';
export const VK__CLIENT__ID = process.env.REACT_APP_VK_CLIENT_ID || '51780578';

export const APP__ROUTS = {
    main: '/',
    feats: '/подвиги',
    featsId: ':id',
    contests: '/конкурсы',
    puzzle: '/пазл',
    puzzleGame: 'игра',
    profileLayout: '/профиль',
    gallery: '/галерея',
    galleryMediaItemId: ':id',
    notExistRouteRedirect: '*',
    userLayout: '',
    userInfo: '',
    userContestsWork: 'works',
    userContests: 'contests',
    adminLayout: 'админ-панель',
    adminGallery: '',
    adminContests: 'конкурсы',
    adminFeats: 'подвиги',
    oldGallery: '/gallery',
};

export const STATUS__CODE = {
    notAuth: 401,
};

export const GALLERY__INITIAL__RECORDS__LIMIT = 7;
export const GALLERY__RECORDS__PER__ONE__PAGINATION = 8;

export const USER__PLACEMENTS__DATA = [
    {
        value: 1,
        option: 'Участник',
    },
    {
        value: 2,
        option: 'Финалист',
    },
    {
        value: 3,
        option: 'Призер',
    },
    {
        value: 4,
        option: 'Победитель',
    },
];
