import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '../../../shared/store/interceptor';

export interface IContest {
    id: number;
    name: string;
    description: string;
    end_date: string;
    is_active: boolean;
    image_url: string;
    start_date: string;
    rubric: string;
    priority: number;
    document_url: string;
    results_link: string;
    media_type: null;
    media_type_id: number;
}

export const contestsApi = createApi({
    reducerPath: 'contestsApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getContests: builder.query<IContest[], void>({
            query: () => 'contests',
        }),
    }),
});

export const { useGetContestsQuery } = contestsApi;
