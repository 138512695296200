import styles from './index.module.scss';
import Input from '../../../../../shared/ui/Input';
import { useGalleryItemContestForm } from '../../model/galleryItemContestForm';
import Button from '../../../../../shared/ui/Button';
import { TextArea } from '../../../../../shared/ui/TextArea';
import Searchbar from '../../../../../shared/ui/SearchBar';

interface IGalleryItemContestForm {
    mediaType: number;
    contest: number;
}

export const GalleryItemContestForm: React.FC<IGalleryItemContestForm> = ({
    mediaType,
    contest,
}) => {
    const {
        watchHero,
        heroRegister,
        heroErrorText,

        watchHeroAvatar,
        heroAvatarRegister,
        heroAvatarErrorText,

        watchEventDate,
        eventDateRegister,
        eventDateErrorText,

        watchHeroCity,
        cities,
        heroCityRegister,
        heroCityErrorText,
        changeCityValue,

        watchDescription,
        descriptionRegister,
        descriptionErrorText,

        watchHeroLink,
        heroLinkRegister,
        heroLinkErrorText,

        watchAuthor,
        authorRegister,
        authorErrorText,

        watchOtherLink,
        otherLinkRegister,

        isSubmitting,
        handleSubmit,
    } = useGalleryItemContestForm(mediaType, contest);
    return (
        <div>
            <form className={styles['gallery-item-contest-form']} onSubmit={handleSubmit}>
                <h2 className={styles['gallery-item-contest-form-title']}>Отправить работу</h2>

                <Input
                    title="Имя героя"
                    register={heroRegister}
                    type="text"
                    isEmpty={!watchHero}
                    className={styles['gallery-item-contest-form__input']}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    errorText={heroErrorText}
                />

                <Input
                    title="Фотография героя"
                    register={heroAvatarRegister}
                    type="file"
                    isEmpty={!watchHeroAvatar}
                    className={styles['gallery-item-contest-form__input']}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    errorText={heroAvatarErrorText}
                    atr={{ accept: 'image/*' }}
                />

                <Input
                    title="Дата совершения подвига "
                    register={eventDateRegister}
                    type="date"
                    isEmpty={!watchEventDate}
                    className={styles['gallery-item-contest-form__input']}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    errorText={eventDateErrorText}
                />

                <Searchbar
                    className={styles['gallery-item-contest-form__input']}
                    options={cities}
                    isEmpty={!watchHeroCity}
                    type={'text'}
                    title="Город героя"
                    register={heroCityRegister}
                    changeValue={changeCityValue}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    errorText={heroCityErrorText}
                />

                <TextArea
                    title="Описание подвига"
                    register={descriptionRegister}
                    text={watchDescription}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    className={styles['gallery-item-contest-form__input']}
                    errorText={descriptionErrorText}
                />

                <Input
                    title="Ссылка на источник или материал, связанный с героем "
                    register={heroLinkRegister}
                    type="text"
                    isEmpty={!watchHeroLink}
                    className={styles['gallery-item-contest-form__input']}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    errorText={heroLinkErrorText}
                />

                <Input
                    title="Ссылка на подтверждающие документы и файлы"
                    register={otherLinkRegister}
                    type="text"
                    isEmpty={!watchOtherLink}
                    className={styles['gallery-item-contest-form__input']}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                />

                <Input
                    title="ФИО участника акции"
                    register={authorRegister}
                    type="text"
                    isEmpty={!watchAuthor}
                    className={styles['gallery-item-contest-form__input']}
                    titleClassName={styles['gallery-item-contest-form__input-title']}
                    errorText={authorErrorText}
                />

                <Button
                    text="Отправить"
                    type="submit"
                    variant="orange"
                    className={styles['gallery-item-contest-form__create-btn']}
                    isDisabled={isSubmitting}
                />
            </form>
        </div>
    );
};
