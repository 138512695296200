import ReactDOM from 'react-dom/client';
import './app/styles/index.scss';
import { RouterProvider } from 'react-router-dom';
import router from './app/router';
import { Provider } from 'react-redux';
import { store } from './shared/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);
