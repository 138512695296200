import styels from './index.module.scss';
import Input from '../../../../../shared/ui/Input';
import Button from '../../../../../shared/ui/Button';
import clsx from 'clsx';
import { useSignUpFormState } from '../../model/signUp';
import Searchbar from '../../../../../shared/ui/SearchBar';
import ViewIcon from '../../../../../entities/feat/assets/ViewIcon';
import { useState } from 'react';
import { subtractYears, getDayMothYearDateFormat } from '../../../../../shared/utils/date';

interface ISignUpForm {
    navMenu?: JSX.Element;
    setModalStatus: () => void;
}

export const SignUpForm: React.FC<ISignUpForm> = ({ navMenu, setModalStatus }) => {
    const {
        handleSubmit,

        watchName,
        nameRegister,
        nameErrorText,

        watchMail,
        mailRegister,
        mailErrorText,

        watchLocation,
        locationRegister,
        locationErrorText,
        changeLocationValue,

        watchPassword,
        passwordRegister,
        passwordErrorText,

        watchConfirmPassword,
        confirmPasswordRegister,
        confirmPasswordErrorText,

        watchBirthday,
        birthdayRegister,
        birthdayErrorText,

        cities,
    } = useSignUpFormState(setModalStatus);
    const [passwordInputType, setType] = useState<'password' | 'text'>('password');

    return (
        <div className={styels['signup']}>
            <form className={styels['signup__form']} onSubmit={handleSubmit}>
                <h2 className={styels['signup__form-title']}>Регистрация</h2>
                <Input
                    isEmpty={!watchName}
                    type={'text'}
                    title="Фамилия, имя и отчество"
                    register={nameRegister}
                    errorText={nameErrorText}
                    titleClassName={styels['signup__form-input-title']}
                />
                <Input
                    className={styels['signup__form-input']}
                    isEmpty={!watchMail}
                    type={'text'}
                    title="Почта"
                    register={mailRegister}
                    errorText={mailErrorText}
                    titleClassName={styels['signup__form-input-title']}
                />
                <Searchbar
                    className={styels['signup__form-input']}
                    options={cities}
                    isEmpty={!watchLocation}
                    type={'text'}
                    title="Откуда вы?"
                    register={locationRegister}
                    errorText={locationErrorText}
                    changeValue={changeLocationValue}
                    titleClassName={styels['signup__form-input-title']}
                />
                <div className={styels['signup__form-input-container']}>
                    <Input
                        className={styels['signup__form-input']}
                        isEmpty={!watchPassword}
                        type={passwordInputType}
                        title="Придумайте пароль"
                        register={passwordRegister}
                        errorText={passwordErrorText}
                        titleClassName={styels['signup__form-input-title']}
                    />
                    {watchPassword && (
                        <ViewIcon
                            className={styels['signup__icon']}
                            onClick={() => {
                                setType((prevState) => {
                                    return prevState === 'password' ? 'text' : 'password';
                                });
                            }}
                        />
                    )}
                </div>
                <Input
                    className={styels['signup__form-input']}
                    isEmpty={!watchConfirmPassword}
                    type={'password'}
                    title="Подтвердите пароль"
                    register={confirmPasswordRegister}
                    errorText={confirmPasswordErrorText}
                    titleClassName={styels['signup__form-input-title']}
                />
                <Input
                    className={styels['signup__form-input']}
                    isEmpty={!watchBirthday}
                    type={'date'}
                    title="Дата рождения"
                    register={birthdayRegister}
                    errorText={birthdayErrorText}
                    titleClassName={styels['signup__form-input-title']}
                    atr={{ max: subtractYears(new Date(), 6) }}
                />
                <Button
                    text="Регистрация"
                    variant="primary"
                    type="submit"
                    className={clsx(styels['signup__form-btn'], styels['signup__form-btn'])}
                />
                {navMenu}
            </form>
        </div>
    );
};
