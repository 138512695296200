import { APP__ROUTS } from '../../../shared/consts';

interface INavLink {
    name: string;
    path: string;
    iconVariant: 'home' | 'gallery' | 'deeds' | 'contest' | 'profile' | 'puzzle';
}

export const NAV_LINKS_DATA: INavLink[] = [
    {
        name: 'Главная',
        path: APP__ROUTS.main,
        iconVariant: 'home',
    },
    {
        name: 'Галерея',
        path: APP__ROUTS.gallery,
        iconVariant: 'gallery',
    },
    {
        name: 'Подвиги',
        path: APP__ROUTS.feats,
        iconVariant: 'deeds',
    },
    {
        name: 'Конкурсы',
        path: APP__ROUTS.contests,
        iconVariant: 'contest',
    },
    // {
    //     name: 'Пазл',
    //     path: APP__ROUTS.puzzle,
    //     iconVariant: 'puzzle',
    // },
];

export const PROFILE__LINK__DATA: INavLink = {
    name: 'Профиль',
    path: APP__ROUTS.profileLayout,
    iconVariant: 'profile',
};
