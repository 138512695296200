import { IMediaType } from './types';
import { BASE__API__URL } from '../../../shared/consts';

export async function requestMediaTypes(): Promise<IMediaType[] | []> {
    try {
        const response = await fetch(`${BASE__API__URL}gallery/media_types`);
        if (response.ok) {
            const data: IMediaType[] = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}
