import styles from './index.module.scss';
import AdvirtismentItem from '../AdvirtismentItem';
import DobroLogo from '../../assets/dobroLogo.webp';
import WeTogethereLogo from '../../assets/weTogethereLogo.webp';


const HeaderAdvertisment = () => {
    return (
        <div className={styles['header__advertisement']}>
            <div className={styles['header__advertisement-info']}>
                <ul>
                    <AdvirtismentItem mark="★" text="Лучший медиапроект ЮФО и СКФО" />
                    <AdvirtismentItem mark="★" text="Победитель международной премии #МЫВМЕСТЕ" />
                </ul>
                <ul className={styles['header__advertisement-logo-list']}>
                    <li>
                        <img
                            className={styles['header__advertisement-logo-list-dobro-logo']}
                            src={DobroLogo}
                            alt="логотип сайта добро.ру"
                        />
                    </li>
                    <li>
                        <img
                            className={styles['header__advertisement-logo-list-togethere-logo']}
                            src={WeTogethereLogo}
                            alt="логотип международной премии #мыВместе"
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HeaderAdvertisment;
