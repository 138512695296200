import FooterLogoContainer from '../FooterLogoContainer';
import FooterLayout from '../FooterLayout';
import FooterNav from '../FooterNav';
import FooterPolicy from '../FooterPolicy';
import FooterCopyright from '../FooterCopyright';

export const Footer = ({ className = '' }) => {
    return (
        <FooterLayout className={className}>
            <FooterLogoContainer />
            <FooterNav />
            <FooterPolicy />
            <FooterCopyright />
        </FooterLayout>
    );
};
