import Modal from '../../shared/ui/Modal';
import styles from './index.module.scss';
import { SignInForm } from '../../features/authentication/signIn';
import { SignUpForm } from '../../features/authentication/signUp';
import { ResetPassForm } from '../../features/authentication/resetPass';
import {
    changeModalVisibleStatus,
    redirectToSignIn,
    redirectToSignUp,
    redirectToResetPass,
    getModalStatus,
    getCurrentRote,
} from '../../entities/authModal';
import { ResendAuthMesgForm } from '../../features/authentication/resendAuthMesg';
import { useAppSelector, useAppDispatch } from '../../shared/store';

const AuthFormRouter = () => {
    const modalStatus = useAppSelector(getModalStatus);
    const currenRoute = useAppSelector(getCurrentRote);
    const dispatch = useAppDispatch();
    const toggleModalstatus = () => {
        dispatch(changeModalVisibleStatus());
    };

    const navigateToSignUp = () => {
        dispatch(redirectToSignUp());
    };

    const navigateToSignIn = () => {
        dispatch(redirectToSignIn());
    };

    const navigateToResetPass = () => {
        dispatch(redirectToResetPass());
    };

    const signInRouting = (
        <ul className={styles['auth-router__nav-list']}>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignUp}>
                Регистрация
            </li>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToResetPass}>
                Восстановить пароль
            </li>
        </ul>
    );

    const resetPassRouting = (
        <ul className={styles['auth-router__nav-list']}>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignIn}>
                Вход
            </li>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignUp}>
                Регистрация
            </li>
        </ul>
    );

    const signUpRouting = (
        <ul className={styles['auth-router__nav-list']}>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToSignIn}>
                Вход
            </li>
            <li className={styles['auth-router__nav-list-item']} onClick={navigateToResetPass}>
                Восстановить пароль
            </li>
        </ul>
    );

    const currentForm = {
        signIn: (
            <SignInForm
                navMenu={signInRouting}
                setModalStatus={toggleModalstatus}
                actionBtn={<ResendAuthMesgForm />}
            />
        ),
        resetPassword: <ResetPassForm navMenu={resetPassRouting} />,
        signUp: <SignUpForm navMenu={signUpRouting} setModalStatus={toggleModalstatus} />,
    }[currenRoute];

    return (
        <Modal
            isOpen={modalStatus}
            setStatus={toggleModalstatus}
            className={styles['auth-router__modal']}
        >
            {currentForm}
        </Modal>
    );
};

export default AuthFormRouter;
