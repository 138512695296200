import Modal from '../../shared/ui/Modal';
import { useCreateModalState } from '../../shared/hooks/modal';
import styles from './index.module.scss';
import Gramophone from './assets/gramophone.png';
import CupImg from './assets/cup.png';
import MedalImg from './assets/medal.png';
import Button from '../../shared/ui/Button';
import { useEffect, useState } from 'react';
import { getDayMothYearDateFormat } from '../../shared/utils/date';

interface IContestBanner {
    contestModal?: React.ReactNode;
}

export const ContestBanner: React.FC<IContestBanner> = ({ contestModal }) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();
    const [bannerStatus, setBannerStatus] = useState(false);

    const vkOnClick = () => {
        window.open('https://vk.com/app5898182_-218711548#s=2910094', '_blank');
    };

    useEffect(() => {
        const popUpData = localStorage.getItem('podvig-popup-data');
        if (popUpData === null) {
            const currentDate = getDayMothYearDateFormat(new Date().toISOString());
            const popUpData = {
                [currentDate]: true,
            };
            localStorage.setItem('podvig-popup-data', JSON.stringify(popUpData));
            setBannerStatus(true);
        }
        if (popUpData) {
            const popUpDataHashMap = JSON.parse(popUpData);
            const currentDate = getDayMothYearDateFormat(new Date().toISOString());
            if (popUpDataHashMap[currentDate] === undefined) {
                const newData = { ...popUpDataHashMap, [currentDate]: true };
                localStorage.setItem('podvig-popup-data', JSON.stringify(newData));
                setBannerStatus(true);
            }
        }
    }, []);
    return (
        <>
            <Modal
                isOpen={bannerStatus}
                modalClassName={styles['banner__modal']}
                setStatus={() => {
                    setBannerStatus(false);
                }}
                additionalElements={
                    <>
                        <img src={MedalImg} className={styles['banner__medal-img']}></img>
                        <img src={CupImg} className={styles['banner__cup-img']}></img>
                        <img src={Gramophone} className={styles['banner__gramophone-img']}></img>
                    </>
                }
            >
                <article className={styles['banner']}>
                    <h2 className={styles['banner__title']}>
                        Прими участие в онлайн-акции «Расскажи о подвиге»
                    </h2>
                    <span className={styles['banner__description']}>
                        Уникальная возможность рассказать историю о героическом поступке своих
                        родственников или друзей, собственном подвиге или незнакомого человека, чью
                        историю хочешь рассказать. На основе историй будут созданы медиаматериалы на
                        примере тех, что размещены на этой странице.
                    </span>
                    <span className={styles['banner__mobile-description']}>
                        Пришли информацию о герое, чтобы сохранить его историю! На основе не менее
                        50 историй мы создадим медиаматериалы, как в разделе «Подвиги».
                    </span>
                    <div className={styles['banner__actions-container']}>
                        <Button
                            text="Подробнее"
                            variant="white"
                            type="button"
                            onClick={toggleModalStatus}
                        />
                        <Button
                            text="Подписаться на акцию в ВК"
                            variant="light-orange-gradient"
                            type="button"
                            onClick={vkOnClick}
                        />
                    </div>
                </article>
            </Modal>
            <Modal setStatus={toggleModalStatus} isOpen={isModalOpen}>
                {contestModal}
            </Modal>
        </>
    );
};
