import { ISignUpForm } from './types';

export const INITIAL__FROM__STATE: ISignUpForm = {
    name: '',
    mail: '',
    location: '',
    password: '',
    confirmPassword: '',
    birthday: '',
};

export const NAME__INPUT = 'name';

export const MAIL__INPUT = 'mail';

export const LOCATION__INPUT = 'location';

export const PASSWORD__INPUT = 'password';

export const CONFIRM__PASSWORD__INPUT = 'confirmPassword';

export const BIRTHDAY__INPUT = 'birthday';
