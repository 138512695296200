import React from 'react';
import styles from './index.module.scss';

interface IAdvirtismentItemProps {
    text: string;
    mark: string;
}

const AdvirtismentItem: React.FC<IAdvirtismentItemProps> = ({ mark, text }) => {
    return (
        <li className={styles['header__advertisement-list-item']}>
            <span className={styles['header__advertisement-list-item-mark']}>
                {mark}
            </span>
            <span className={styles['header__advertisement-list-item-text']}>
                {text}
            </span>
        </li>
    );
};

export default AdvirtismentItem;
