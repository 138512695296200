import { APP__ROUTS } from '../../../../../shared/consts';

export const ADMIN__NAVLINKS__DATA = [
    {
        name: 'Галерея',
        path: APP__ROUTS.adminGallery,
    },

    {
        name: 'Подвиги',
        path: APP__ROUTS.adminFeats,
    },
    {
        name: 'Конкурсы',
        path: APP__ROUTS.adminContests,
    },
];
