import { IGalleryDTO, IGalleryItemCreateForm } from './types';

export function createGalleryItemDto(galleryItem: IGalleryItemCreateForm): IGalleryDTO {
    return {
        name: galleryItem.name,
        description: galleryItem.description ? galleryItem.description : undefined,
        hero: galleryItem.hero ? galleryItem.hero : undefined,
        hero_city: galleryItem.heroCity ? galleryItem.heroCity : undefined,
        hero_link: galleryItem.heroLink ? galleryItem.heroLink : undefined,
        author: galleryItem.author ? galleryItem.author : undefined,
        publish_date: galleryItem.publishDate
            ? new Date(galleryItem.publishDate).toISOString()
            : undefined,
        media: galleryItem.media ? galleryItem.media : undefined,
        media_type_id: Number(galleryItem.mediaTypeId),
        other_link: galleryItem.otherLink ? galleryItem.otherLink : undefined,
        hero_picture: galleryItem.heroPictureUrl,
        eventDate:  galleryItem.eventDate ? galleryItem.eventDate : undefined,
    };
}
