import { useForm, SubmitHandler } from 'react-hook-form';
import { IGalleryItemCreateForm } from './types';
import {
    DESCRIPTION__INPUT,
    HERO__INPUT,
    HERO__CITY__INPUT,
    HERO__LINK__INPUT,
    AUTHOR__INPUT,
    OTHER__LINK__INPUT,
    HERO__AVATAR__INPUT,
    EVENT__DATE__INPUT,
} from './consts';
import { checkErrorText } from '../../../../shared/hooks/reactHookForm';
import { createFormData } from '../../../../shared/utils/transformData';
import { createGalleryItemDto } from './utils';
import { createGalleryItem, linkGalleryItemWithContest } from './api';
import { createAlert, createAlertOptions } from '../../../../entities/alerts';
import { useAppDispatch } from '../../../../shared/store';
import { optionsList } from '../../../../shared/ui/SearchBar';
import { useState, useEffect } from 'react';
import { getCities } from '../../../../shared/api/adresses';

export function useGalleryItemContestForm(mediaType: number, contest: number) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<IGalleryItemCreateForm>({
        mode: 'onTouched',
        defaultValues: {
            name: ' ',
            description: '',
            hero: '',
            heroCity: '',
            heroLink: '',
            author: '',
            publishDate: '',
            media: '',
            mediaTypeId: mediaType,
            contestId: contest,
        },
    });
    const [cities, setCities] = useState<optionsList>([]);
    const dispatch = useAppDispatch();
    const submit: SubmitHandler<IGalleryItemCreateForm> = async (data) => {
        const adaptedFormData: any = createGalleryItemDto(data);

        const formData = new FormData();
        createFormData(adaptedFormData, formData);
        const createdGalleryItem = await createGalleryItem(formData);

        if (createdGalleryItem) {
            await linkGalleryItemWithContest(contest, createdGalleryItem.id);
            dispatch(
                createAlert(
                    createAlertOptions(
                        'Работа успешно добавлена',
                        'Благодарим за участие в проекте Подвиг.РФ'
                    )
                )
            );
        } else {
            dispatch(
                createAlert(
                    createAlertOptions(
                        'Что-то пошло не-так',
                        'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки Подвиг.РФ для получения помощи.',
                        'middle'
                    )
                )
            );
        }
    };

    const watchHero = watch(HERO__INPUT);
    const heroRegister = register(HERO__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 256,
            message: 'превышено допустимое количество символов',
        },
    });

    const watchHeroAvatar = watch(HERO__AVATAR__INPUT);
    const heroAvatarRegister = register(HERO__AVATAR__INPUT, {
        required: 'Поле обязательно',
    });

    const watchEventDate = watch(EVENT__DATE__INPUT);
    const eventDateRegister = register(EVENT__DATE__INPUT, {
        required: 'Поле обязательно',
    });

    const watchDescription = watch(DESCRIPTION__INPUT);
    const descriptionRegister = register(DESCRIPTION__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 10000,
            message: 'превышено допустимое количество символов',
        },
    });

    const watchHeroCity = watch(HERO__CITY__INPUT);
    const heroCityRegister = register(HERO__CITY__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 256,
            message: 'превышено допустимое количество символов',
        },
    });

    const changeCityValue = (city: string | number) => {
        setValue(HERO__CITY__INPUT, String(city));
    };

    const watchHeroLink = watch(HERO__LINK__INPUT);
    const heroLinkRegister = register(HERO__LINK__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 256,
            message: 'превышено допустимое количество символов',
        },
    });

    const watchAuthor = watch(AUTHOR__INPUT);
    const authorRegister = register(AUTHOR__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 256,
            message: 'превышено допустимое количество символов',
        },
    });

    const watchOtherLink = watch(OTHER__LINK__INPUT);
    const otherLinkRegister = register(OTHER__LINK__INPUT, {
        required: false,
        maxLength: {
            value: 256,
            message: 'превышено допустимое количество символов',
        },
    });

    useEffect(() => {
        const fetch = setTimeout(async () => {
            const cities = await getCities(watchHeroCity);
            const adaptCitites = cities.map((city) => {
                return {
                    ...city,
                    option: String(city.value),
                };
            });
            setCities(adaptCitites);
        }, 400);
        return () => clearTimeout(fetch);
    }, [watchHeroCity]);

    return {
        watchHero,
        heroRegister,
        heroErrorText: checkErrorText(HERO__INPUT, errors),

        watchHeroAvatar,
        heroAvatarRegister,
        heroAvatarErrorText: checkErrorText(HERO__AVATAR__INPUT, errors),

        watchEventDate,
        eventDateRegister,
        eventDateErrorText: checkErrorText(EVENT__DATE__INPUT, errors),

        watchHeroCity,
        heroCityRegister,
        cities,
        changeCityValue,
        heroCityErrorText: checkErrorText(HERO__CITY__INPUT, errors),

        watchDescription,
        descriptionRegister,
        descriptionErrorText: checkErrorText(DESCRIPTION__INPUT, errors),

        watchHeroLink,
        heroLinkRegister,
        heroLinkErrorText: checkErrorText(HERO__LINK__INPUT, errors),

        watchAuthor,
        authorRegister,
        authorErrorText: checkErrorText(AUTHOR__INPUT, errors),

        watchOtherLink,
        otherLinkRegister,

        isSubmitting,
        handleSubmit: handleSubmit(submit),
    };
}
