import { BASE__API__URL } from '../../../shared/consts';
import { IGalleryItem } from './types';
import { Params, ParamParseKey, ActionFunctionArgs } from 'react-router-dom';
import { APP__ROUTS } from '../../../shared/consts';

export async function getGalleryMediaItemById(id: number): Promise<IGalleryItem | null> {
    try {
        let galleryItem = null;
        if (id) {
            const request = await fetch(`${BASE__API__URL}gallery/${id}`);
            if (request.ok) {
                galleryItem = await request?.json();
            } else {
                galleryItem = null;
            }
        }
        return galleryItem;
    } catch (err) {
        return null;
    }
}

const Paths = {
    featsDetail: APP__ROUTS.gallery,
} as const;

interface FeatLoaderArgs extends ActionFunctionArgs {
    params: Params<ParamParseKey<typeof Paths.featsDetail>>;
}

export async function loadGalleryMediaItemById({
    params,
}: FeatLoaderArgs): Promise<IGalleryItem | null> {
    const { id } = params;

    const response = await getGalleryMediaItemById(Number(id));

    return response;
}
