import { useForm, SubmitHandler } from 'react-hook-form';
import {
    validateMail,
    validateFullName,
    validatePassword,
} from '../../../../shared/utils/validators';
import { INITIAL__FROM__STATE } from './consts';
import { useState } from 'react';
import { optionsList } from '../../../../shared/ui/SearchBar';
import {
    NAME__INPUT,
    MAIL__INPUT,
    PASSWORD__INPUT,
    LOCATION__INPUT,
    CONFIRM__PASSWORD__INPUT,
    BIRTHDAY__INPUT,
} from './consts';
import { useEffect } from 'react';
import { getCities } from '../../../../shared/api/adresses';
import { checkErrorText } from '../../../../shared/hooks/reactHookForm';
import { ISignUpForm } from './types';
import { signUpRequest } from './api';
import { getSignUpDto } from './utils';
import { createAlert, createAlertOptions } from '../../../../entities/alerts';
import { useAppDispatch } from '../../../../shared/store';

type setModalStatus = () => void;

export function useSignUpFormState(setModalStatus: setModalStatus) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<ISignUpForm>({
        mode: 'onTouched',
        defaultValues: INITIAL__FROM__STATE,
    });
    const dispatch = useAppDispatch();
    const [cities, setCities] = useState<optionsList>([]);

    const submit: SubmitHandler<ISignUpForm> = async (data) => {
        const response = await signUpRequest(getSignUpDto(data));
        if (response) {
            if (response.status === 200) {
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Подтвердите почту!',
                            'На почту отправлено письмо. Пожалуйста, перейдите по ссылке в письме для подтверждения почты.',
                            'middle'
                        )
                    )
                );
                setModalStatus();
            } else if (response.status === 409) {
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Другой аккаунт уже привязан к данному адресу почты!',
                            'Необходимо указать адрес почты, который не использовался при регистрации'
                        )
                    )
                );
            } else {
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Что-то пошло не-так',
                            'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки Подвиг.РФ для получения помощи.',
                            'middle'
                        )
                    )
                );
            }
        }
    };

    const changeLocationValue = (city: string | number) => {
        setValue(LOCATION__INPUT, String(city));
    };

    const watchName = watch(NAME__INPUT);
    const watchMail = watch(MAIL__INPUT);
    const watchLocation = watch(LOCATION__INPUT);
    const watchPassword = watch(PASSWORD__INPUT);
    const watchConfirmPassword = watch(CONFIRM__PASSWORD__INPUT);
    const watchBirthday = watch(BIRTHDAY__INPUT);

    const nameRegister = register(NAME__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validateFullName: (v) =>
                validateFullName(v) ||
                'Полное имя должно состоять из кириллицы, без специальных знаков.',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
        minLength: {
            value: 4,
            message: 'минимальное количество символов - 4',
        },
    });

    const mailRegister = register(MAIL__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validateMail: (v) =>
                validateMail(v) || 'Введённая почта невалидна. Пожалуйста, выберите другую.',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const locationRegister = register(LOCATION__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
        minLength: {
            value: 0,
            message: 'Название населённого пункта не может быть короче двух символов.',
        },
    });

    const passwordRegister = register(PASSWORD__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validatePassword: (v) =>
                validatePassword(v) ||
                'Пароль должен содержать хотя бы одну строчную букву, одну заглавную букву, одну цифру и хотя бы один спец символ такой как @, $, !, %, *, ?, &',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
        minLength: {
            value: 8,
            message: 'Пароль должен состоять минимум из восьми символов',
        },
    });

    const confirmPasswordRegister = register(CONFIRM__PASSWORD__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validateConfirmedPassword: (v) =>
                watchPassword === v || 'Введённые пароли не совпадают.',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const birthdayRegister = register(BIRTHDAY__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    useEffect(() => {
        const fetch = setTimeout(async () => {
            const cities = await getCities(watchLocation);
            const adaptCitites = cities.map((city) => {
                return {
                    ...city,
                    option: String(city.value),
                };
            });
            setCities(adaptCitites);
        }, 400);
        return () => clearTimeout(fetch);
    }, [watchLocation]);

    return {
        handleSubmit: handleSubmit(submit),

        watchName,
        nameRegister,
        nameErrorText: checkErrorText(NAME__INPUT, errors),

        watchMail,
        mailRegister,
        mailErrorText: checkErrorText(MAIL__INPUT, errors),

        watchLocation,
        locationRegister,
        locationErrorText: checkErrorText(LOCATION__INPUT, errors),
        changeLocationValue,

        watchPassword,
        passwordRegister,
        passwordErrorText: checkErrorText(PASSWORD__INPUT, errors),

        watchConfirmPassword,
        confirmPasswordRegister,
        confirmPasswordErrorText: checkErrorText(CONFIRM__PASSWORD__INPUT, errors),

        watchBirthday,
        birthdayRegister,
        birthdayErrorText: checkErrorText(BIRTHDAY__INPUT, errors),

        cities,
    };
}
