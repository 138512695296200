import { BASE__API__URL, STATUS__CODE, APP__ROUTS } from '../../../shared/consts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserData } from './types';

export const fetchUserData = createAsyncThunk(
    'session/fetchUserData',
    async function (): Promise<IUserData | null> {
        try {
            const response = await fetch(`${BASE__API__URL}user/me`);
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }
);

export const fetchSignOut = createAsyncThunk(
    'session/fetchSignOut',
    async function (): Promise<boolean> {
        try {
            const response = await fetch(`${BASE__API__URL}user/logout`);
            if (response.ok) {
                return true;
            } else {
                throw new Error();
            }
        } catch (err) {
            throw new Error();
        }
    }
);

interface IUpdateUserData {
    fio: string;
    email: string;
    city: string;
    date_of_birth: string;
    avatar_url: string;
}

export const fetchUppdateUser = createAsyncThunk(
    'session/fetchUppdateUser',
    async function (updateUserData: FormData): Promise<IUpdateUserData> {
        try {
            const response = await fetch(`${BASE__API__URL}user/me/update`, {
                method: 'PUT',
                headers: {},
                body: updateUserData,
            });
            if (response.ok) {
                const result = await response.json();
                return result;
            } else if (response.status === STATUS__CODE.notAuth) {
                window.location.replace(APP__ROUTS.main);
                throw new Error();
            } else {
                throw new Error();
            }
        } catch (err) {
            throw new Error();
        }
    }
);

export const fetchSignInvK = createAsyncThunk(
    'session/fetchSignInvK',
    async function (token: string): Promise<boolean> {
        try {
            const response = await signInvK(token);
            if (response.ok) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            throw new Error();
        }
    }
);

export async function signInvK(token: string) {
    const response = await fetch(`${BASE__API__URL}user/oauth/vk_auth?code=${token}`, {
        method: 'GET',
    });
    return response;
}
