export const DESCRIPTION__INPUT = 'description';

export const HERO__INPUT = 'hero';

export const HERO__CITY__INPUT = 'heroCity';

export const HERO__LINK__INPUT = 'heroLink';

export const AUTHOR__INPUT = 'author';

export const PHONE__NUMBER__INPUT = 'phoneNumber';

export const OTHER__LINK__INPUT = 'otherLink';

export const HERO__AVATAR__INPUT = 'heroPictureUrl';

export const EVENT__DATE__INPUT = 'eventDate';
