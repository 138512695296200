import Button from '../Button';
import styels from './index.module.scss';
import clsx from 'clsx';
import ShareIcon from '../../assets/ShareIcon';
import VkLogo from '../../assets/VkLogo';

interface IShareButton {
    shareListClasses?: string;
    shareIconOnClick?: () => void;
    shareVkOnClick?: () => void;
}

const ShareButton: React.FC<IShareButton> = ({
    shareListClasses = '',
    shareIconOnClick,
    shareVkOnClick,
}) => {
    return (
        <div className={styels['share-btn__container']} onClick={(e) => e.stopPropagation()}>
            <Button
                text="Поделиться"
                type="button"
                variant="primary"
                className={styels['share-btn']}
            />
            <ul className={clsx(shareListClasses, styels['share-btn__icons-list'])}>
                <li>
                    <ShareIcon className={styels['share-btn__icon']} onClick={shareIconOnClick} />
                </li>
                <li>
                    <VkLogo className={styels['share-btn__icon']} onClick={shareVkOnClick} />
                </li>
            </ul>
        </div>
    );
};

export default ShareButton;
