import { APP__ROUTS } from '../../../shared/consts';

export const HEADER__NAVLINKS__DATA = [
    {
        name: 'Главная',
        path: APP__ROUTS.main,
    },
    {
        name: 'Подвиги',
        path: APP__ROUTS.feats,
    },
    {
        name: 'Галерея',
        path: APP__ROUTS.gallery,
    },
    {
        name: 'Конкурсы',
        path: APP__ROUTS.contests,
    },
    // {
    //     name: 'Пазл',
    //     path: APP__ROUTS.puzzle,
    // },
];
